import { DateTime } from "luxon";
import { getI18n } from "react-i18next";

import { DateTimeFormat, DateTimeStyle } from "./helpersEnums";

const getLanguage = (): string => getI18n().language;

export const formatInteger = (value: number | string): string => {
  const lang = getLanguage();
  const integerFormatter = new Intl.NumberFormat(lang, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return isNaN(Number(value)) ? "" : integerFormatter.format(Number(value));
};

export const formatFloat = (
  value: number | string | undefined,
  isCurrency: boolean = true,
  signDisplay: keyof Intl.NumberFormatOptionsSignDisplayRegistry | undefined = undefined,
): string => {
  const lang = getLanguage();
  const floatFormatter = new Intl.NumberFormat(lang, {
    maximumFractionDigits: 4,
    minimumFractionDigits: 2,
    signDisplay,
    ...(isCurrency && {
      style: "currency",
      currency: "EUR",
    }),
  });
  return isNaN(Number(value)) ? "-" : floatFormatter.format(Number(value));
};

export const formatHighPrecision = (value: number | string): string => {
  const lang = getLanguage();
  const highPrecisionFormatter = new Intl.NumberFormat(lang, {
    maximumFractionDigits: 4,
    minimumFractionDigits: 2,
  });
  return isNaN(Number(value)) ? "" : highPrecisionFormatter.format(Number(value));
};

export const formatDate = (value: Date | DateTime): string => {
  const lang = getLanguage();
  const fullDateFormatter = new Intl.DateTimeFormat(lang, {
    timeStyle: undefined,
    dateStyle: "medium",
  });
  try {
    return fullDateFormatter.format(Number(value));
  } catch (error) {
    // code inside try failed for DateTime.utc(2023, 11, 17, 13, 8)
    // luxon has own methods for date to string formatting
    return formatDateTime(DateTime.isDateTime(value) ? value : DateTime.fromJSDate(value)) ?? "-";
  }
};

export const formatDateTime = (
  value: DateTime | undefined,
  format: DateTimeFormat = DateTimeFormat.Full,
  dateStyle: DateTimeStyle = DateTimeStyle.Medium,
): string | null => {
  if (!value) return "-";
  let valueToUse = value;
  if (!DateTime.isDateTime(valueToUse)) {
    valueToUse = DateTime.fromJSDate(valueToUse);
    if (!valueToUse.isValid) return null;
  }

  const lang = getLanguage();
  const timeStyleToUse = [DateTimeFormat.Time, DateTimeFormat.Full].includes(format) ? "short" : undefined;
  const dateStyleToUse = [DateTimeFormat.Date, DateTimeFormat.Full].includes(format) ? dateStyle : undefined;

  try {
    const fullDateFormatter = new Intl.DateTimeFormat(lang, {
      timeStyle: timeStyleToUse,
      dateStyle: dateStyleToUse,
    });
    return fullDateFormatter.format(Number(valueToUse)); // test if it code be removed and replaced by code in catch
  } catch (error) {
    // code inside try failed for DateTime.utc(2023, 11, 17, 13, 8)
    // luxon has own methods for date to string formatting
    return valueToUse.toLocaleString(
      {
        timeStyle: timeStyleToUse,
        dateStyle: dateStyleToUse,
      },
      {
        locale: lang,
      },
    );
  }
};

export const formatDateTimeToOnlyMonthStringAndYearNumeric = (value: DateTime | undefined): string | null => {
  if (!value) return null;
  let valueToUse = value;
  if (!DateTime.isDateTime(valueToUse)) {
    valueToUse = DateTime.fromJSDate(valueToUse);
    if (!valueToUse.isValid) return null;
  }

  const lang = getLanguage();

  try {
    const fullDateFormatter = new Intl.DateTimeFormat(lang, {
      month: "long",
      year: "numeric",
    });
    return fullDateFormatter.format(Number(valueToUse)); // test if it code be removed and replaced by code in catch
  } catch (error) {
    // code inside try failed for DateTime.utc(2023, 11, 17, 13, 8)
    // luxon has own methods for date to string formatting
    return null;
  }
};

export const formatTime = (value: Date): string => {
  const lang = getLanguage();
  const fullDateFormatter = new Intl.DateTimeFormat(lang, {
    timeStyle: "short",
    dateStyle: undefined,
  });
  return fullDateFormatter.format(Number(value));
};

export const formatContactDisplayName = (salutation?: string, firstName?: string, lastName?: string): string => {
  const notNullValues = [salutation, firstName, lastName].filter((value): value is string => value != null);
  return notNullValues.length > 0 ? notNullValues.join(" ") : "-";
};
