import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { IndexIdentifier, SprengnetterIndexOverviewItemResponse } from "@/api/sprengConnectApi";
import { ChevronDownIcon, CsvIcon, DocumentsIcon, InfoIcon, PdfIcon } from "@/assets";
import { useDownloadFile } from "@/hooks";

const currentYear = new Date().getFullYear();

type DownloadDocumentationButtonProps = {
  selectedIndex: SprengnetterIndexOverviewItemResponse;
};

export const DownloadDocumentationButton = ({ selectedIndex }: DownloadDocumentationButtonProps): JSX.Element => {
  const { t } = useTranslation("PlaygroundPage");
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchorEl);

  const { downloadFileString } = useDownloadFile();

  const handleDownloadCsvPdfClick = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setMenuAnchorEl(null);
  };

  const handleDownloadReport = (): void => {
    if (selectedIndex.identifier !== IndexIdentifier.SIM) return;
    downloadFileString("/accompanyingDocumentation/Report_S-IM_2024.pdf", "Report_S-IM_2024.pdf");
  };

  const handleDownloadData = (): void => {
    if (selectedIndex.identifier !== IndexIdentifier.SIM) return;
    downloadFileString("/accompanyingDocumentation/Uebergabe_S_IM_Index_2024.csv", "Übergabe_S-IM_Index_2024.csv");
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<DocumentsIcon />}
        endIcon={<ChevronDownIcon />}
        onClick={handleDownloadCsvPdfClick}
      >
        {t("downloadDocumentationButton.downloadDocumentation")}
      </Button>
      <Menu anchorEl={menuAnchorEl} open={open} onClose={handleClose}>
        <Box sx={{ display: "flex", p: 1 }}>
          <Typography variant="menuTitle" sx={{ mr: 1 }}>
            {t("downloadDocumentationButton.title")}
          </Typography>
          <InfoIcon />
        </Box>
        <MenuItem onClick={handleDownloadReport}>
          <PdfIcon sx={{ mr: 1 }} />
          {t("downloadDocumentationButton.report", { indexTitle: selectedIndex.title, currentYear })}
        </MenuItem>
        <MenuItem onClick={handleDownloadData}>
          <CsvIcon sx={{ mr: 1 }} />
          {t("downloadDocumentationButton.dataDownload", { indexTitle: selectedIndex.title, currentYear })}
        </MenuItem>
      </Menu>
    </>
  );
};
