import { sprengConnectApi } from "../sprengConnectApi";
import { SprengConnectApiResponse } from "../types";
import { BatchResponse, BatchUploadRequest } from "../types/batchController";
import { BatchOutputResponse } from "../types/batchController/response/BatchOutputResponse";
import { BatchStatusResponse } from "../types/batchController/response/BatchStatusResponse";

export const batchController = sprengConnectApi.injectEndpoints({
  endpoints: (builder) => ({
    batchUpload: builder.mutation<SprengConnectApiResponse<BatchResponse[]> | undefined, BatchUploadRequest>({
      query: (body) => ({
        url: "batches/Batch",
        method: "POST",
        data: body,
      }),
      //invalidatesTags: no invalidate tags, because always new data
    }),
    getBatchStatuses: builder.query<SprengConnectApiResponse<BatchStatusResponse[]> | undefined, string[]>({
      query: (batchIds) => ({
        url: "batches/Status?uuids=" + batchIds.join(","),
        method: "GET",
      }),
      //providesTags: no provide tags, because always new data
    }),
    getBatchOutput: builder.query<SprengConnectApiResponse<BatchOutputResponse[]> | undefined, string>({
      query: (batchId) => ({
        url: `batches/${batchId}/Result`,
        method: "GET",
      }),
      //providesTags: no provide tags, because we don't know if there is data or not and we don't want to block the query
    }),
  }),
});

export const { useBatchUploadMutation, useGetBatchStatusesQuery, useGetBatchOutputQuery } = batchController;
